.zedoc-popover {
  z-index: 999;
}

.zedoc-popover[data-popper-placement^='top'] > .zedoc-popover__arrow {
  --top: 12px;
  --border-width: 8px;
  --border-right-width: 12px;

  bottom: 0px;

  &::before,
  &::after {
    @apply absolute w-0 h-0 border-solid;

    content: '';
    right: calc(100% - 1px);
  }

  &::after {
    right: var(--top);
    border-color: var(--color-surface) transparent transparent transparent;
    border-width: var(--border-width);
    border-top-width: var(--border-right-width);
  }

  &::before {
    right: calc(var(--top) - 1px);
    border-color: currentColor transparent transparent transparent;
    border-width: calc(var(--border-width) + 1px);
    border-top-width: calc(var(--border-right-width) + 1px);
  }
}

.zedoc-popover[data-popper-placement^='bottom'] > .zedoc-popover__arrow {
  top: -4px;
}

.zedoc-popover[data-popper-placement^='left'] > .zedoc-popover__arrow {
  right: -4px;
}

.zedoc-popover[data-popper-placement^='right'] > .zedoc-popover__arrow {
  --border-width: 8px;
  --border-right-width: 12px;

  left: -1px;

  &::before,
  &::after {
    @apply absolute w-0 h-0 border-solid;

    content: '';
    right: calc(100% - 1px);
  }

  &::after {
    top: -6px;
    border-color: transparent var(--color-surface) transparent transparent;
    border-width: var(--border-width);
    border-right-width: var(--border-right-width);
  }

  &::before {
    top: -7px;
    border-color: transparent currentColor transparent transparent;
    border-width: calc(var(--border-width) + 1px);
    border-right-width: calc(var(--border-right-width) + 1px);
  }
}
