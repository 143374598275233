@tailwind base;
@tailwind components;
@tailwind utilities;

/* INFO: color-underlay is used mainly for popover background for now */

:root {
  --auto-grid-min-size: theme('width.64');

  /* Text */
  --color-body: #405166;
  /* Colors */
  --color-primary: #0f3689;
  --color-primary-200: #e3ecfa;
  --color-primary-300: #dee4f8;
  --color-primary-400: #cfd7e7;
  --color-primary-700: #37579d;
  --color-primary-800: #0f3689;
  --color-primary-muted: theme('colors.blue.800');
  --color-surface: theme('colors.white');
  --color-surface-500: var(--color-neutral-700);
  --color-surface-900: #f3f5f8;
  --color-underlay: theme('colors.white');
  --color-success: #69c0a5;
  --color-success-muted: #a5d9c9;
  --color-info: #94a9f1;
  --color-info-muted: #bfcbf7;
  --color-danger: #ea7d7d;
  --color-danger-400: #ff7474;
  --color-danger-500: #ff5858;
  --color-danger-600: #ea7d7d;
  --color-danger-700: #e24a4a;
  --color-danger-800: #dc2626;
  --color-danger-muted: #f2b1b1;
  --color-warning: #fee66e;
  --color-warning-muted: #fef0a8;
  --color-neutral: #c0cbd8;
  --color-neutral-400: #f3f5f8;
  --color-neutral-500: #efeff1;
  --color-neutral-600: #dfe2e5;
  --color-neutral-700: #bfc5cc;
  --color-neutral-800: #405166;
  --color-neutral-900: #202933;
  --color-neutral-muted: #c9ced4;
  --color-disabled: var(--color-neutral-500);
  --color-divider: #dfe2e5;
  --color-input-border: theme('colors.gray.200');
  /* On background colors */
  --color-on-primary: theme('colors.white');
  --color-on-primary-800: theme('colors.white');
  --color-on-surface: theme('colors.gray.900');
  --color-on-success: theme('colors.gray.900');
  --color-on-info: theme('colors.gray.900');
  --color-on-danger: theme('colors.gray.900');
  --color-on-danger-500: theme('colors.gray.900');
  --color-on-danger-600: #202933;
  --color-on-warning: theme('colors.gray.900');
  --color-on-neutral: theme('colors.gray.900');
  --color-on-neutral-800: theme('colors.white');
  --color-on-disabled: #c5cbd5;
  /* Gradient */
  --gradient-surface-start: rgba(255, 255, 255, 0.7);
  --gradient-surface-end: var(--color-surface) 22.5%;
  /* Animation */
  --animation-translate-y-from: 100%;
  --animation-translate-x-from: -100%;
  /* Text */
  --text-color-secondary: theme('colors.gray.500');
  /* PW v2 specific legacy color */
  --color-pw2-header: #e2f0fd;
  --color-pw2-secondary: #d8e0e9;
  --color-pw2-tertiary: #e3ecfc;
}

html.dark {
  color-scheme: dark;

  /* Text */
  --color-body: #e2e3e4;
  /* Colors */
  --color-primary: #477eeb;
  --color-primary-300: #272f38;
  --color-primary-700: #77b5ff;
  --color-primary-800: #3390ff;
  /* --color-surface: #1e2630; */
  --color-surface: #343c45;
  --color-surface-500: #545a62;
  --color-surface-900: #1e2630;
  --color-underlay: var(--color-neutral-500);
  --color-success: #2fa782;
  --color-success-muted: #60bc9f;
  --color-info: #448aff;
  --color-info-muted: #639dff;
  --color-danger: #ef5350;
  --color-danger-400: #f2706d;
  --color-danger-500: #ef5350;
  --color-danger-600: #f48c8a;
  --color-danger-700: #f2706d;
  --color-danger-800: #ef5350;
  --color-danger-muted: #f2706d;
  --color-warning: #feea86;
  --color-warning-muted: #fef1ae;
  --color-neutral: #a5a5a5;
  --color-neutral-400: #545a62;
  --color-neutral-500: #4b5159;
  --color-neutral-600: #424951;
  --color-neutral-700: #343c45;
  --color-neutral-800: #272f38;
  --color-neutral-muted: #394049;
  --color-disabled: #343c45;
  --color-divider: #73787f;
  --color-input-border: theme('colors.gray.400');
  /* On background colors */
  --color-on-surface: theme('colors.white');
  --color-on-disabled: #73787f;
  /* --color-on-success: theme('colors.gray.900');
  --color-on-info: theme('colors.gray.900');
  --color-on-danger: theme('colors.gray.900');
  --color-on-warning: theme('colors.gray.900');
  --color-on-neutral: theme('colors.gray.900');
  --color-on-disabled: theme('colors.white'); */
  /* Gradient */
  /* FIXME: double check dark mode gradient colour */
  --gradient-surface-start: rgba(30, 38, 48, 0.7);
  /* Text */
  --text-color-secondary: theme('colors.gray.300');
}

body {
  background-color: var(--color-surface-900);
  color: var(--color-body);
}

/* They are defined in TW Base but have to redefine them due to antd */
a {
  transition: inherit;
}

a:active,
a:hover {
  color: inherit;
  text-decoration: inherit;
}

hr {
  @apply border-divider;
}

/* Inputs */

input::placeholder {
  @apply text-sm;
}

.z-input-container > div > *:first-child {
  @apply rounded-tl-lg rounded-bl-lg;
}
.z-input-container > div > *:last-child {
  @apply rounded-tr-lg rounded-br-lg;
}

input.input-number-reset::-webkit-outer-spin-button,
input.input-number-reset::-webkit-inner-spin-button {
  @apply appearance-none;
}

/* Ant Design Popover */

.ant-popover .ant-popover-inner {
  @apply rounded-md;
}
.ant-popover .ant-popover-title {
  @apply px-2 py-3 my-0 mx-4 min-h-0 text-sm font-medium border-divider;
}
.ant-popover .ant-popover-inner-content {
  @apply pt-3 px-6 pb-6;
}
.ant-popover .ant-popover-arrow,
.ant-popover .ant-popover-arrow-content {
  @apply w-6 h-6 shadow-none;
}
.ant-popover .ant-popover-inner,
.ant-popover .ant-popover-arrow-content {
  @apply bg-underlay;
}
.ant-popover,
.ant-popover-title,
.ant-popover-inner-content {
  color: inherit;
}

/* Ant Design Dropdown */

.ant-dropdown-menu:not(.ant-dropdown-menu-submenu-popup) {
  @apply bg-underlay;
}
.ant-dropdown,
.ant-dropdown-menu-item:not(.ant-dropdown-menu-item-disabled),
.ant-dropdown-menu-submenu-title:not(.ant-dropdown-menu-submenu-title-disabled) {
  color: inherit;
}
.ant-dropdown-menu-item-disabled:hover,
.ant-dropdown-menu-submenu-title-disabled:hover {
  background-color: inherit;
}
.ant-dropdown-menu-item:not(.ant-dropdown-menu-item-disabled):hover,
.ant-dropdown-menu-submenu-title:not(.ant-dropdown-menu-submenu-title-disabled):hover {
  @apply bg-neutral-600;
}
.ant-dropdown-menu-submenu-title .ant-dropdown-menu-item-divider {
  @apply bg-divider;
}
.ant-dropdown .ant-dropdown-menu-submenu-title {
  @apply flex items-center pr-3;
}
.ant-dropdown .ant-dropdown-menu-submenu-expand-icon {
  @apply static mis-4;
}
[dir='rtl'] .ant-dropdown .ant-dropdown-menu-submenu-expand-icon {
  @apply transform rotate-180;
}
.ant-dropdown .ant-dropdown-menu-item-icon,
.ant-dropdown-menu .ant-dropdown-menu-item-icon {
  @apply mie-3;
}
.ant-dropdown
  .ant-dropdown-menu-submenu-expand-icon
  .ant-dropdown-menu-submenu-arrow-icon {
  @apply text-xs align-middle;

  color: inherit;
  line-height: 0;
}
.ant-dropdown-menu-title-content {
  @apply text-sm;
}

/* AmCharts */

.amcharts-chart-div text,
.amChartsLegend text {
  fill: currentColor;
}

/* Ant Design Notification */

.ant-notification-notice-icon {
  @apply mis-1;
}
.ant-notification-notice-with-icon .ant-notification-notice-message {
  @apply mbe-1 mis-12 pie-6;
}
.ant-notification-notice-close {
  top: inherit;
  right: inherit;

  @apply block-start-4 inline-end-6;
}

/* Tag */

.z-tag a {
  @apply -m-2 p-2;

  &:focus {
    @apply text-white no-underline;
  }
}

/* Table */

.z-table thead,
.z-table tbody,
.z-table tr {
  display: contents;
}

.z-table th,
.z-table td {
  text-align: left;
  @apply p-4;
}

.z-table th {
  @apply font-normal;
}

.z-table td {
  @apply font-medium truncate;
}

.z-table th:first-child,
.z-table td:first-child {
  @apply pl-0;
}
.z-table th:last-child,
.z-table td:last-child {
  @apply pr-0;
}
.z-table thead tr:first-child th {
  @apply pt-0;
}
.z-table tbody tr:last-child td {
  @apply pb-0;
}

.z-table thead tr th {
  @apply border-b;
}

.z-table thead tr th:first-child,
.z-table tbody tr td:first-child {
  @apply border-r;
}

/* custom */

.z-table th:not(:first-child) {
  @apply text-center;
}
.z-table td:not(:first-child) {
  @apply text-center;
}

/* Zedoc Button */

/* NOTE: In Tailwind v3 it's possible to style child elements */
/* So we could later refactor it and remove this and other custom classes */
.z-btn > svg {
  @apply h-1/2;
}

/* Zedoc Pagination */

.z-pagination.z-pagination.z-pagination.z-pagination.z-pagination
  .ant-select-selector {
  @apply border-primary bg-surface rounded-sm;
}
.z-pagination.z-pagination.z-pagination.z-pagination.z-pagination
  .ant-select-selection-item {
  @apply flex items-center text-xs text-primary;
}
.z-pagination.z-pagination.z-pagination.z-pagination.z-pagination
  .ant-select-arrow {
  @apply text-primary;

  width: 10px;
  height: 10px;
  font-size: 10px;
  margin-top: -4px;
}

/* Zedoc Calendar */

.z-calendar {
  display: grid;
  grid-template-columns: repeat(7, minmax(120px, 1fr));
  grid-template-rows: 40px;
  grid-auto-rows: 120px;
  overflow-x: auto;
}
.z-calendar__day-name {
  @apply text-xs leading-10 border-b px-2 font-medium;
}
.z-calendar__day:nth-of-type(7n + 7) {
  @apply border-r-0;
}

.z-calendar__day:nth-of-type(n + 8):nth-of-type(-n + 14) {
  grid-row: 3;
}
.z-calendar__day:nth-of-type(7n + 2) {
  grid-column: 2/2;
}
.z-calendar__milestone {
  /* @apply px-2 py-1 text-xs rounded-sm; */

  align-self: end;
}

.z-calendar__day {
  &:nth-of-type(7n + 7) {
    border-right: 0;
  }

  &:nth-of-type(n + 1):nth-of-type(-n + 7) {
    grid-row: 1;
  }

  &:nth-of-type(n + 8):nth-of-type(-n + 14) {
    grid-row: 2;
  }

  &:nth-of-type(n + 15):nth-of-type(-n + 21) {
    grid-row: 3;
  }

  &:nth-of-type(n + 22):nth-of-type(-n + 28) {
    grid-row: 4;
  }

  &:nth-of-type(n + 29):nth-of-type(-n + 35) {
    grid-row: 5;
  }

  &:nth-of-type(n + 36):nth-of-type(-n + 42) {
    grid-row: 6;
  }

  &:nth-of-type(7n + 1) {
    grid-column: 1/1;
  }

  &:nth-of-type(7n + 2) {
    grid-column: 2/2;
  }

  &:nth-of-type(7n + 3) {
    grid-column: 3/3;
  }

  &:nth-of-type(7n + 4) {
    grid-column: 4/4;
  }

  &:nth-of-type(7n + 5) {
    grid-column: 5/5;
  }

  &:nth-of-type(7n + 6) {
    grid-column: 6/6;
  }
  &:nth-of-type(7n + 7) {
    grid-column: 7/7;
  }
}

/* Markdown content */

.zedoc-react-markdown {
  a {
    @apply text-primary-800 hover:underline;
  }

  /* NOTE: Only block level parent adds vertical spacing */
  /* for example, to visually separate nested <p> tags */
  div& {
    @apply stack-6;
  }
}

/* Helpers */

.ltr {
  direction: ltr;
}

.rtl {
  direction: rtl;
}
