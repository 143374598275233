.z-react-select-container .z-react-select__control {
  @apply bg-surface border-divider min-h-10;
}
.z-react-select-container .z-react-select__control:hover {
  @apply border-primary;
}
.z-react-select-container .z-react-select__single-value {
  @apply text-on-surface text-sm;
}
.z-react-select-container .z-react-select__multi-value {
  @apply bg-neutral-500 my-0 min-w-min;
}
.z-react-select-container .z-react-select__multi-value__label {
  @apply text-on-surface;
}

/* Input Container */
.z-react-select-container .z-react-select__input-container {
  @apply text-on-surface;
}

/* Multi Container */
.z-react-select-container .z-react-select__value-container--is-multi {
  @apply flex-nowrap overflow-x-auto px-0 mx-2;
}

/* NOTE: Double selector to increase specificity */
.z-react-select__menu-portal.z-react-select__menu-portal {
  @apply z-50;
}
.z-react-select__menu-portal .z-react-select__menu {
  @apply bg-surface;
}
.z-react-select__menu-portal .z-react-select__option {
  @apply px-4 py-2 text-on-surface text-sm;
}
.z-react-select__menu-portal .z-react-select__option--is-focused {
  @apply bg-neutral-400;
}
.z-react-select__menu-portal .z-react-select__option:active,
.z-react-select__menu-portal .z-react-select__option--is-selected {
  @apply bg-primary-300;
}
/* NOTE: "z-react-select__group-heading--is-hidden" is a custom class */
.z-react-select__menu-portal .z-react-select__menu {
  .z-react-select__group-heading--is-hidden + div {
    @apply hidden;
  }
}

/* Zedoc React Select Disabled Styling */

.z-react-select-container.z-react-select--is-disabled {
  @apply pointer-events-auto cursor-not-allowed;
}
.z-react-select-container .z-react-select__control--is-disabled {
  @apply border-neutral-400 pointer-events-none;
}
.z-react-select-container .z-react-select__placeholder {
  @apply text-sm;
}
.z-react-select-container .z-react-select__control--is-disabled .z-react-select__placeholder {
  @apply text-on-disabled;
}
.z-react-select-container .z-react-select__control--is-focused {
  @apply ring-2 ring-primary;
}
.z-react-select-container
  .z-react-select__control--is-disabled
  .z-react-select__single-value {
  @apply text-on-disabled;
}
.z-react-select-container
  .z-react-select__control--is-disabled
  .z-react-select__multi-value__label {
  @apply text-on-disabled;
}
.z-react-select-container
  .z-react-select__control--is-disabled
  .z-react-select__multi-value__remove {
  @apply hidden;
}
