.zedoc-input-group.zedoc-input-group {
  display: flex;

  .zedoc-input-group__item-container {
    @apply min-w-0;
  }

  .zedoc-input-group__item,
  .z-react-select__control {
    @apply rounded-none border-divider border-r-0 border-l-0;
  }

  .zedoc-input-group__item:not(:disabled),
  .z-react-select__control:not(.z-react-select__control--is-disabled) {
    @apply hover:border-divider hover:ring-1 ring-inset ring-primary transition-shadow;
  }

  .zedoc-input-group__item-container:first-child {
    .zedoc-input-group__item,
    .z-react-select__control {
      @apply rounded-tl rounded-bl border-l;
    }
  }

  .zedoc-input-group__item-container:last-child {
    .zedoc-input-group__item,
    .z-react-select__control {
      @apply rounded-tr rounded-br border-r;
    }
  }

  .zedoc-input-group__item-container:not(:first-child) {
    .zedoc-input-group__item,
    .z-react-select__control {
      @apply border-l;
    }
  }
}
